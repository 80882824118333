<template>
  <div class="login-container">
    <div>
      <el-form class="form" ref="user" :rules="formRules" :model="user" label-width="80px">
        <el-form-item label="手机号" prop="tel">
          <el-input v-model="user.tel" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="pwd">
          <el-input v-model="user.pwd" placeholder="请输入密码"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submitForm" :loading="loading">登录</el-button>
          <el-button @click="resetForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-footer class="footer">
      <span>做接口使用 无实质内容</span>
      <a href="https://beian.miit.gov.cn">鄂ICP备2022001233号-1</a>
    </el-footer>
  </div>
</template>
<script>
export default {
  name: "login",
  data() {
    return {
      user: {
        tel: "", // 手机号
        pwd: "", // 验证码
      },
      formRules: {
        // 表单验证规则
        mobile: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { pattern: /^1[3|5|7|8|9]\d{9}$/, message: "请输入正确的手机号" },
        ],
        code: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      loading: false, // 登录的 loading 状态
    };
  },
  methods: {
    async submitForm() {
      // 开启 loading ...
      this.loading = true;
      let response = await this.$request.post("/login", this.user);
      if (response.data.success) {
        this.loading = false;
        window.localStorage.setItem("user", JSON.stringify(response.data));
        this.$router.push({ path: "/progress" });
      } else {
        this.loading = false;
        this.$message.error(response.data.msg);
      }

    },
    // 重置功能
    resetForm() {
      this.$refs.user.resetFields();
    },
  },
};
</script>
<style lang="less" scoped>
.login-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("../../assets/login_bg.jpg") no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  .form {
    min-width: 300px;
    padding: 50px 50px 50px 30px;
    background-color: #fff;
  }
}

.footer {
  position: absolute;
  bottom: 0;
}
</style>
